body.news {
    position: relative;
}

.news {
    .hero {
        background-image: url(/assets/img/headernews.jpg);
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
        padding-top: 180px;
        padding-bottom: 150px;
        height: 100vh;
        

        h1 {
            text-align: center;
        }
    }



    .main {

        padding-top: 130px;

h2 {
    text-align: center;
}

        h4,
        h5 {
            color: $white;
            display: inline-block;
            margin-bottom: 0;
            font-size: 1.25rem;
            font-family: montserratregular;
        }
        .cell {
            margin-bottom: 4rem;
            border-left: 1px solid $white;
            padding: 1rem 1rem 1rem 1.5rem;
        }

        .cell.events,
        .cell.jobs,
        .cell.jobmail {
            border: none;

            h2 {
                text-align: center;
                font-family: montserratregular;
                
            }
        }

        .cell.jobs {
            margin-top: 160px;
        }

        .cell.jobmail {
            text-align: center;
        }
    }
}
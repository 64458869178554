html {
    height: 100%;
}

body.login {
    color: $white;

    display: flex;

    min-height: 100%;
    background-image: url(/../assets/img/banner1.jpg);
    background-image: linear-gradient(to bottom, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.8) 100%),url(/../assets/img/banner1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
     flex-direction: column;
    justify-content: space-between;
    align-items: center;

    header {
        flex-shrink: 0;
        align-self: center;
        position: relative;
    }

    .logininner {
        max-width: 800px;
        min-width: 300px;
        text-align: center;

        input {
            font-family: 'montserratbold';
            padding: 2rem 2.5rem;
            margin: .5rem 0 2rem 0;
            text-align: center;
            font-size: 1.75rem;
        }
        
        button {
            font-family: 'montserratbold';
            font-size: 2rem;
            text-transform: uppercase;
            padding: .7rem 2.5rem;
        }

        p {
            margin-top: 3rem;
        }
    }

    footer {
        flex-shrink: 0;
    }
}
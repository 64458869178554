.cbuttons {
    margin-top: 2rem;
    margin-bottom: 230px;
    transition: all 1s;
}

.cbutton,
.kontakt .cbutton {
    text-align: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 210px;
    position: relative;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    background-image: url(/../assets/img/rahmenleer.png);
    transition: all 1s;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 210px;
    }
}

.cbuttons:hover {
    .cbutton {
        background-image: url(/../assets/img/rahmengrau.png);

        a {
            color: #575756;
        }
    }

    .cbutton:hover {
        background-image: url(/../assets/img/rahmenleer.png);

        a {
            color: map-get($foundation-palette, primary);
        }
    }
}

.konzeptbuttons {
    .orbit-bullets {

        .cbutton {

            background-image: url(/../assets/img/rahmen_w.png);

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 210px;
                width: 100%;
                margin: 0;
                background-color: transparent;
                color: #fff;
                transition: all .3s;
            }
        }

        .cbuttons:hover {
            .cbutton {
                background-image: url(/../assets/img/rahmen_w.png);

                button {
                    color: #fff;
                }
            }

            .cbutton:hover {
                background-image: url(/../assets/img/rahmenleer.png);

                button {
                    color: map-get($foundation-palette, primary);
                }
            }
        }
    }
}

.grid-container.kontaktbuttons {
    margin-top: 166px;
}
.agb,
.datenschutz,
.impressum {
    
    .main {
        margin-top: 150px;
        text-align: center;
        margin-bottom: 150px;
    }
    
    
}
.hero {
  z-index: 100;
  position: relative;

  .orbit-previous {
    left: 50px;
     @include breakpoint (small up) {
        display: none;
    }
    @include breakpoint (large up) {
        display: block;
    }
}

.orbit-next {
    right: 50px;
    @include breakpoint (small up) {
        display: none;
    }
    @include breakpoint (large up) {
        display: block;
    }
}
  
img.orbit-image {
    outline: 0;
    box-shadow: inset 0px -90px 45px -49px rgba(0,0,0,0.75);
    -moz-box-shadow: inset 0px -90px 45px -49px rgba(0,0,0,0.75);
}

.orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(0, 0, 0, 0.0);
}
}
ul.orbit-container {
    height: auto !important;
}
li.orbit-slide {
    max-height: none !important;
}


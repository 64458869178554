.galerie {
    .main {
        margin-top: 150px;
    }
    
    .css-grid-container.galerieview {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-column-gap: 35px;
  grid-row-gap: 50px;
  grid-auto-flow: row;
    }
    
    .gallery-item {
        position: relative;
    }
    
    .css-grid-item img {
        width: 100%;
        
    }
    
    .galeriehover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0);
        transition: background-color 1s;
        
        
        
        h3 {
            color: $white;
            text-transform: uppercase;
            font-family: montserratbold;
            font-size: 2rem;
            position: absolute;
            left:15px;
            bottom:15px;
            padding: 0;
            margin: 0;
            opacity:0;
            transition: opacity.75s;
            
            span {
                display: block;
                font-size: 1rem;
                text-transform: none;
            }
        }
    }
    .galeriehover:hover {
        background-color: rgba(0,0,0,0.75);
        cursor: pointer;
        h3 {
            opacity: 1;
            color: $white;
            text-transform: uppercase;
            font-family: montserratbold;
            font-size: 2rem;
            position: absolute;
            left:15px;
            bottom:15px;
            padding: 0;
            margin: 0;
            
            span {
                display: block;
                font-size: 1rem;
                text-transform: none;
            }
        }
    }
    
    .partner {
        margin-bottom: 180px;
        
    }
}
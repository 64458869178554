body {
  border-top: 4px solid map-get($foundation-palette,primary); 
  position: relative;
  font-family: montserratlight;
}

header.css-grid-container {
    @include breakpoint(small) { 
        grid-template-rows: 54px 54px; 
        grid-template-columns: 1fr; 
        grid-column-gap: 0; 
        grid-row-gap: 0;
    }
    
    @include breakpoint(medium up) { 
        grid-template-rows: 54px; 
        grid-template-columns: 1fr; 
        grid-column-gap: 0; 
        grid-row-gap: 0;
    }
}

header {
      @include breakpoint(large down) { 
  margin: 0 0 52px 0;
  position: absolute;
  background-color: transparent;
  z-index: 999;
  width: 100%;
  padding-top: 0;
    }
    
      @include breakpoint(large) { 
  margin: 0 0 52px 0;
  position: absolute;
  background-color: transparent;
  z-index: 999;
  width: 1024px;
  padding-top: 52px;
    }
    
    @include breakpoint(xlarge) { 
  margin: 0 0 52px 0;
  position: absolute;
  background-color: transparent;
  z-index: 999;
  width: 1200px;
  padding-top: 52px;
    }
    
    @include breakpoint(xxlarge) { 
  margin: 0 0 52px 0;
  position: absolute;
  background-color: transparent;
  z-index: 999;
  width: 1400px;
  padding-top: 52px;
    }
}

.logocell {
    @include breakpoint(small) {
        order:2;
        text-align: center;
        
    }
    
   
    
    @include breakpoint(large) {
        order:1;
        text-align: left;
    }
}

.cell.shrink.hamburger {
    @include breakpoint(small) {
        order:1;
    
    }
    @include breakpoint(large) {
        order:2;
       
    }
}

.cell.shrink.language {
    @include breakpoint(small) {
        order:3;
    
    }
    @include breakpoint(large) {
        order:3;
       
    }
}

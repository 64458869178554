.kontakt {
    .white {
        color: $white;
    }

    .primary {
        color: map-get($foundation-palette, primary);
    }

    .black {
        color: $black;
    }

    .bold {
        font-family: montserratbold, sans-serif;
    }

    .kontakt-item h2,
    .kontakt-item h3 {
        font-size: 5rem;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
        line-height: 1;
    }

    .main {
        margin-bottom: 100px;
        margin-top: 166px;
    }

    .hascards {
        position: relative;
        min-height: 600px;

        h2 {
            margin-right: .5rem;
        }

        h2,
        h3 {
            display: inline-block;
        }

        img {
            width: fit-content;

        }

    }

    .lug {
        background-image: url("/assets/img/kontakt/bild1a.jpg");
        background-size: cover;
        animation-delay: .3s;
        -moz-animation-delay: .3s;
        -webkit-animation-delay: .3s;
    }

    .hauser {
        background-image: url("/assets/img/kontakt/bild2a.jpg");
        background-size: cover;
        animation-delay: .6s;
        -moz-animation-delay: .6s;
        -webkit-animation-delay: .6s;
    }

    .drose {
        background-image: url("/assets/img/kontakt/bild3a.jpg");
        background-size: cover;
        animation-delay: .9s;
        -moz-animation-delay: .9s;
        -webkit-animation-delay: .9s;
    }

    .missy {
        background-image: url("/assets/img/kontakt/bild4a.jpg");
        background-size: cover;
        animation-delay: 1.2s;
        -moz-animation-delay: 1.2s;
        -webkit-animation-delay: 1.2s;
    }

    .cardimg {
        display: block;
        width: 100%;
        height: 100%;
    }

    .cardwrapper {
        position: absolute;
        top: 0;
        left: 0;
        padding: 2rem 1rem;
        background-color: rgba($color: #000000, $alpha: .85);
        opacity: 0;
        transition: opacity .3s;
        height: 100%;
        display: flex;
        align-items: center;
        justify-items: center;
    }

    .cardwrapper:hover,
    .cardwrapper:focus {
        opacity: 1;
        background-size: cover;
    }

    .cardwrapper.lug {
        background-image: url(/assets/img/kontakt/bild1b.jpg);

    }

    .cardwrapper.hauser {
        background-image: url(/assets/img/kontakt/bild2b.jpg);

    }

    .cardwrapper.drose {
        background-image: url(/assets/img/kontakt/bild3b.jpg);

    }

    .cardwrapper.missy {
        background-image: url(/assets/img/kontakt/bild4b.jpg);

    }

    .kontaktformular {

        padding: 130px 0;

        h4 {

            text-align: center;
        }

        address {
            font-style: normal;
            text-align: center;
        }
    }

    input::placeholder,
    textarea::placeholder {
        color: $dark-gray;
        opacity: 1;
    }

    input {
        font-family: 'montserratbold';
        padding: 2rem 2.5rem;
        margin: .5rem 0 2rem 0;
        text-align: center;
        font-size: 1.75rem;
    }

    .submit,
    button {
        font-family: 'montserratbold';
        font-size: 2rem;
        text-transform: uppercase;
        padding: .7rem 2.5rem;
    }

    textarea {
        height: 140px;
        margin-bottom: 3rem;
    }


    .button-termin {
        font-family: montserratbold, sans-serif;
        font-size: 1.2rem;
        text-transform: uppercase;
        letter-spacing: 0.1rem
    }

    .submit.button {
        padding: 0.5em 1em;
        display: block;
        margin: 0 auto 166px auto;
    }

    .button-termin.button {
        padding: 0.5em 1em;
    }

    .showroom {
        margin-top: 130px;

    }

    .showroomorbit {
        margin-bottom: 130px;
    }
}
body.innotectur {
    border-top: 4px solid $color-innotec;
    a {
        color: $color-innotec;
    }
    
    a:hover {
        color: scale-color($color-innotec, $lightness: -14%);
    }
    
    h1,h2,h3,h4,h5 {color: $color-innotec;}
    
    .mainburger::after {
    background: $color-innotec;
    box-shadow: 0 10px 0 $color-innotec, 0 20px 0 $color-innotec;
   
}

 .mainburger:hover::after {
    background: $color-innotec;
    box-shadow: 0 10px 0 $color-innotec, 0 20px 0 $color-innotec;
   
}

nav.mainmenu ul a {
    color: $color-innotec;
}

.orbit-controls {
    display: none;
}

.animatedpics {
    margin-top: 166px;
}

.kontaktbuttons {
    margin-top: 166px;
}


.cbuttons:hover .cbutton:hover a {
    color: $color-innotec;
}

.cbutton,
.cbuttons:hover .cbutton:hover {
    background-image: url(/../assets/img/rahmenkupfer.png);
}


footer {

    border-bottom: 4px solid $color-innotec;

}
    
}

.konzept {
    
    .grid-container.full.main {
        margin-bottom: 40px;
    }
    .main {
        background-image: url(/assets/img/konzept/header_konzept.jpg);
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
        padding-top: 150px;
        
    }
    
    h2 {
        color: #fff;
    }
    
    ul.cbuttons {
        margin-left: 0rem;
    list-style-type: none;
    margin-top: 180px;
    margin-bottom: 300px;
}
    
.css-grid-container.full.content-onlineberatung,
    .css-grid-container.full.content-vorortberatung,
    .css-grid-container.full.content-showroom  {
    grid-template-columns: 1.4875fr 1fr;
        grid-template-rows: auto;
        justify-items: stretch;
        position: relative;
}

.css-grid-item.konzept_links {
    grid-column: 1 / span 1;
    grid-row: 1;
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    
}
.css-grid-item.konzept_rechts {
    grid-column: 2 / span 1;
    grid-row: 1;
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
   
}

.grid-container.css-grid-item {
    grid-column: 1 / span 2;
    grid-row: 1;
    z-index: 2;
}

    .grid-container.full.content-onlineberatung,
    .grid-container.full.content-vorortberatung,
    .grid-container.full.content-showroom {
        border-top: 2px solid map-get($foundation-palette,primary);
        border-bottom: 2px solid map-get($foundation-palette,primary);
        @include breakpoint(small) {
            margin-bottom: 2rem;
        }
        @include breakpoint(large) {
            margin-bottom: 2rem;
        }
    }
    
    .content-onlineberatung,
    .content-vorortberatung,
    .content-showroom {
        @include breakpoint(small) {
            margin-bottom: 2rem;
        }
        @include breakpoint(large) {
           
        }
    }
    
    .content-onlineberatung.display,
    .content-vorortberatung.display,
    .content-showroom.display {
        @include breakpoint(small) {
            margin-bottom: 2rem;
        }
        @include breakpoint(large) {
           
            display: block;
        }
    }
    
    
    .content-onlineberatung .konzept_rechts {
        
        @include breakpoint(large) {
       
        }
        
    }
    
    .content-vorortberatung .konzept_rechts {
        
        @include breakpoint(large) {
        
        }
        
    }
    
    .content-showroom .konzept_rechts {
       
        @include breakpoint(large) {
        
        }
        
    }
    
    .links {
        
        padding: 40px 0;
    }
    
    .cbuttons {
        margin-bottom: 40px;
    }
    
    .cbutton a {
        color: #ffffff;
    }
    
    .cbuttons.kontakt {
        margin-bottom: 230px;
    }
    
    .kontakt .cbutton a {
        color: map-get($foundation-palette,primary);
    }
    
    .submit,
.button-termin {
    font-family: montserratbold, sans-serif;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem
}


.button-termin.button {
    padding: 0.5em 1em;
    @include breakpoint(large) {
    margin: 50% auto;
}
}
}


.hamburger,
.language {
    z-index: 901;
}

// Hauptnavigation

@include breakpoint(small) {
    nav.mainmenu {
        display: none;
        position: absolute;
        right: 0;
        top: 145px;
        z-index: 900;
        width: 100vw;
        background-color: map-get($foundation-palette, primary);
        height: 100vh;

        ul {
            padding-top: 20px;
            padding-left: 0; 
            padding-bottom: 0;
            text-transform: uppercase;

            li:nth-child(9),
                li:nth-child(12) {
                padding-top: 1rem;
            }
            
            

            a {
                line-height: 0.5;
                letter-spacing: 0.2rem;
                color: $black;
            }

            li {
                text-align: center;
                width: 100vw;
                font-size: 0.8rem;
            }
        }
    }
}
@include breakpoint(large) {
    nav.mainmenu {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 900;
        width: 300px;
        background-color: rgba(0,0,0,0.8);

        ul {
            padding-top: 150px;
            padding-left: 60px; 
            padding-bottom: 40px;
            text-transform: uppercase;

            li:nth-child(9),
                li:nth-child(12) {
                padding-top: 2rem;
            }

            a {
                line-height: 1.8;
                letter-spacing: 0.2rem;
                color: map-get($foundation-palette, primary);
            }

            li {
                text-align: left;
                width: auto;
                font-size: 1rem;
            }
        }
    }
}

// Sprachumschalter

@include breakpoint(small) {
    nav.mainmenu .language {
        display: flex;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;

        ul {
            padding-top: 1rem;
            padding-bottom: 0;
            width: 100vw;
            text-align: center;
            display: flex;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        }

        li {
            text-align: center;
            width: auto;
        }
    }

    .language {
        display: none;
    }
}

@include breakpoint(xlarge) {
    nav.mainmenu .language {
        display: none;
        
    }

    .language {
        display: block;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
}

.cell.shrink.hamburger {
    @include breakpoint(small) {
       
        width: 100vw;
        text-align: center;
        background-color: map-get($foundation-palette, primary);
       padding-bottom: 4px;
        margin: 0 0 12px 0;
    }
    @include breakpoint(large) {
        
        width: auto;
        text-align: center;
        background-color: transparent;
       padding-bottom: 0;
        margin: 0 0.625rem;
    }
} 
.mainburger {
    @include breakpoint(small) {
@include hamburger($black, #323232, 34px, 21px, 1px, 3);
    }
    
    @include breakpoint(large) {
        @include hamburger(map-get($foundation-palette, primary), map-get($foundation-palette, primary), 34px, 21px, 1px, 3);
    }
    
}
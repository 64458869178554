.partner {
    .main {
        margin-top: 150px;
    }

    .grid-container.partnerlogos {
        text-align: center;
        margin-top: 0;
        margin-bottom: 0px;
    }


    .main + .grid-container.partnerlogos .grid-x:first-child {
        margin-top: 50px;
    }

    .grid-container.full.animatedpics {
        margin-top: 190px;
    }
    
        
       
    .partnertext {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(255,255,255,0.5);
        width: 100%;
        height: 100%;
        padding: 2rem;
        color: $black;
        overflow: hidden;
        
    }
    
    p.partnertext {
        margin-bottom: 0;
    }
    
    .partnerhover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        opacity: 1;
        transition: opacity 1s;
        
        
        
     
        }
    
    .partnerhover:hover {
        background-color: rgba(0,0,0,0);
        cursor: pointer;
        opacity: 0;
    }
    
    .plogo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        opacity: 1;
        transition: opacity 1s;
        display: flex;
        justify-content: center;
        align-items: center;
        
        img {
            width: auto;
        }
    }
    
    .partner {
        margin-top: 180px;
        margin-bottom: 180px;
        
    }
    
    .kontaktbuttons {
        margin-top: 180px;
    }
}
.upper {
    text-transform: uppercase;
}

strong,
.language a:hover {
    font-family: 'montserratbold';
    font-weight: bold;
}

.menu a:hover {
    font-weight: bold;
}
.main h1 {
    margin-top: 70px;
}

/* headline under paragraph */
.hup {
    margin-top: -1rem;
}

.css-grid-container.mainbuttons,
.css-grid-container.cbuttons {
    @include breakpoint(large down) { 
        grid-template-rows: 210px 210px; 
        grid-template-columns: 1fr 210px 210px 1fr; 
        grid-column-gap: 15px; 
        grid-row-gap: 15px;


        .css-grid-item.beratung,
        .css-grid-item.email,
        .css-grid-item.onlineberatung {
            grid-column:2 / span 1; 
            grid-row:1 / 1; 
        }
        .css-grid-item.konzept,
        .css-grid-item.chat,
        .css-grid-item.vorortkonzept {
            grid-column:3 / span 1; 
            grid-row:1 / 1; 
        }
        .css-grid-item.planung,
        .css-grid-item.rueckruf,
        .css-grid-item.konzeptshowroom {
            grid-column:2 / span 1; 
            grid-row:2 / 2; 
        }
        .css-grid-item.realisierung,
        .css-grid-item.vorort,
        .css-grid-item.dining {
            grid-column:3 / span 1; 
            grid-row:2 / 2; 
        }
    }

    @include breakpoint(large up) { 
        grid-template-rows: 210px; 
        grid-template-columns: 1fr 210px 210px 210px 210px 1fr; 
        grid-column-gap: 15px; 
        grid-row-gap: 15px;


        .css-grid-item.beratung,
        .css-grid-item.email,
        .css-grid-item.onlineberatung {
            grid-column:2 / span 1; 
            grid-row:1 / 1; 
        }
        .css-grid-item.konzept,
        .css-grid-item.chat,
        .css-grid-item.vorortkonzept  {
            grid-column:3 / span 1; 
            grid-row:1 / 1; 
        }
        .css-grid-item.planung,
        .css-grid-item.rueckruf,
        .css-grid-item.konzeptshowroom {
            grid-column:4 / span 1; 
            grid-row:1 / 1; 
        }
        .css-grid-item.realisierung,
        .css-grid-item.vorort,
        .css-grid-item.dining  {
            grid-column:5 / span 1; 
            grid-row:1 / 1; 
        }
    }
}
.mainbuttons {
    margin-top: 121px;
    margin-bottom: 147px;

    .mainbutton {
        text-align: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 210px;
        position: relative;
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        transition: all 1s;

        a {
            display: block;
            width: 209px;
            height: 210px;
            span {
                position: absolute;
                bottom: 2rem;
                width: 100%;
                left: 0;
            }
        }
    }


    .beratung {
        background-image: url(/../assets/img/beratung1.png);
        transition: all 1s;
    }
    .konzept {
        background-image: url(/../assets/img/konzept2.png);
        transition: all 1s;
    }
    .planung {
        background-image: url(/../assets/img/planung3.png);
        transition: all 1s;
    }
    .realisierung {
        background-image: url(/../assets/img/realisierung4.png);
        transition: all 1s;
    }
}
.mainbuttons:hover {

    .beratung {
        background-image: url(/../assets/img/beratung.png);
        transition: all 1s;
    }
    .konzept {
        background-image: url(/../assets/img/konzept.png);
        transition: all 1s;
    }
    .planung {
        background-image: url(/../assets/img/planung.png);
        transition: all 1s;
    }
    .realisierung {
        background-image: url(/../assets/img/realisierung.png);
        transition: all 1s;
    }
    a {color: #575756;}


    .beratung:hover  {
        background-image: url(/../assets/img/beratung1.png);
        a {color: map-get($foundation-palette,primary);}
    }
    .konzept:hover  {
        background-image: url(/../assets/img/konzept2.png);
        a {color: map-get($foundation-palette,primary);}
    }
    .planung:hover  {
        background-image: url(/../assets/img/planung3.png);
        a {color: map-get($foundation-palette,primary);}
    }
    .realisierung:hover  {
        background-image: url(/../assets/img/realisierung4.png);
        a {color: map-get($foundation-palette,primary);}
    }

}


.animatedpics img {

}

.hideme
{

}

.grid-container.partnerlogos {
    text-align: center;

    margin-top: 147px;
    margin-bottom: 0px;
    .grid-x {
        margin-top: 166px;
    }
    img {


    }

}

.kontaktbuttons h2 {
    margin-top: 208px;

}



.grid-container.sociallinks {
    margin-bottom: 198px;
}

footer {
    margin-top: 140px;
    width: 100%;
    border-bottom: 4px solid map-get($foundation-palette, primary);
    padding-bottom: 2rem;
}

.wow {
    animation-delay: .3s;
    -moz-animation-delay: .3s;
    -webkit-animation-delay: .3s;
}

.bild2 {
    animation-delay: .3s;
    -moz-animation-delay: .3s;
    -webkit-animation-delay: .3s;
}
.bild3, .bild6, .bild8 {
    animation-delay: .6s;
    -moz-animation-delay: .6s;
    -webkit-animation-delay: .6s;
}

.bild4, .bild9 {
    animation-delay: .9s;
    -moz-animation-delay: .9s;
    -webkit-animation-delay: .9s;
}
.partnerlogoslogos .wow:nth-child(1) {
    animation-delay: .6s;
    -moz-animation-delay: .6s;
    -webkit-animation-delay: .6s;
}

.partnerlogoslogos .wow:nth-child(2) {
    animation-delay: .9s;
    -moz-animation-delay: .9s;
    -webkit-animation-delay: .9s;
}

.partnerlogoslogos .wow:nth-child(3) {
    animation-delay: 1.2s;
    -moz-animation-delay: 1.2s;
    -webkit-animation-delay: 1.2s;
}

.partnerlogoslogos .wow:nth-child(4) {
    animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
    -webkit-animation-delay: 1.5s;
}

.fb {
    background-image: url(/../assets/img/facebook.png);

}

.xg {
    background-image: url(/../assets/img/xing.png);

}

.fb,
.xg {
    height: 72px;
    background-size: 100%;
    background-repeat: no-repeat;
    transition: opacity .5s ease;
    display: block;
    width: 72px;
    img {
        opacity: 0;
        transition: opacity .5s ease;
    }
}

.fb:hover,
.xg:hover {

    img {
        opacity: 1;
    }
}
.philosophie {
    .grid-container.full.hero {
        padding-top: 190px;
        background-image: url(/assets/img/philosophie/headerneu.jpg);
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: cover;
    }
    
    blockquote {
         border: none;
         text-align: justify;
         color: $white;
         
         cite {
            color: map-get($foundation-palette, primary);
    display: block;
    font-size: 1.5rem;
    width: 100%;
    text-align: right;
    font-style: normal;
    margin-top: 1rem;
        }
    }
    
    blockquote.heading {
        border: none;
        color: map-get($foundation-palette, primary);
        font-size: 4rem;
        line-height: 1.2;
        margin-bottom: 4rem;
        
        span {
            font-family: montserratbold;
        }
        
        cite {
            color: $white;
    display: block;
   
    width: 100%;
    text-align: right;
    font-style: normal;
    margin-top: 0rem;
        }
    }
    
    .main {
        
        text-align: left;
        padding-bottom: 60px;
        
        
        h3 {
            color: $primary-color;
            padding-top: 60px;
        }

        
    }
    .grid-container.main {
        margin-bottom: 2rem;
    }

    .css-grid-container.animatedpics {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        overflow: hidden;

        .css-grid-item {
            overflow: hidden;
            display: block;
        }

        .bild1 {
            grid-area: 1 / 1 / 3 / 2;
          
        }
        .bild2 {
            grid-area: 1 / 2 / 1 / 3;
        }
        .bild3 {
            grid-area: 1 / 3 / 1 / 4;
        }
        .bild4 {
            grid-area: 2 / 2 / 3 / 3;
        }
        .bild5 {
            grid-area: 2 / 3 / 3 / 4;
        }
       
    }
} 